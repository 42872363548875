

.main-gallery {
    // margin-top: 15rem;
    min-height: 20rem;

    .header-gallery {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        margin-top: 15rem;
        min-width: 15rem;

    
    }
}


