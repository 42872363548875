.main-home-page {
    position: relative;
    justify-content: center;
    padding-bottom: 2rem;
    align-items: center;
    // padding: 10rem;
    display: flex;
    flex-direction: column;

    .background-home {
        background-image: var(--coverImage);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        // opacity: 0.6;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .header-home {
        position: absolute;

        div:last-child{
            margin-top: 1rem;
        }

        div:first-child {}

        h1 {
            font-family: Hero-Font;
            color: var(--fontColorJson);
            font-size: 3rem;
        }

        h2, h3 {
            font-family: Hero-Font;
            color: var(--fontColorJson);
            direction: rtl;
            font-size: 2rem;

        }
    }

    h1 {
        padding: 1rem;
        color: var(--white);
    }
}



.dark-home {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.62;
    z-index: -1;
}