.sponsers-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
    background-color: var(--white);
    padding: 1rem;
    gap: 0.5rem;
}

.sponsers-main-container{
    display: flex;
    flex-direction: column;
    margin-top: auto;
}