.footer-container{
    height: 2rem;
    background-color: var(--backgroundColorJson);
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: auto;
    div{
        a{
            cursor: pointer;
        }

        color: var(--fontColorJson) !important;
    }
    width: 100%;
    text-align: center;

}