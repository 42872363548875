@font-face {
    font-family: General-Font;
    src: url("static/Rubik-Regular.woff") format("woff");
    font-weight: 800;
    font-style: normal;

}
@font-face {
    font-family: Hero-Font;
    src: url("static/Rubik-Light.ttf") format("woff");
    font-weight: 900 !important;
    font-style: normal;

}
@font-face {
    font-family: Bold-Font;
    src: url("static/Rubik-Bold.ttf") format("woff");
    font-weight: 900 !important;
    font-style: normal;

}

