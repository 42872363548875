* {
    // outline: 0.5px solid red !important;
    list-style-type: none;
    margin: 0;
    box-sizing: border-box;
    text-decoration: none;
    h1 ,h2, h3, h4, h5, h6{

        direction: rtl;
    }

    a {
        all: unset;
        text-decoration: none;
        /* no underline */

    }

}

.main-btn {
    padding: 5px 15px;
    border: unset;
    border-radius: 4px;
    color: var(--backgroundColorJson);
    background-color: var(--white);
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 182px;
    letter-spacing: 0.02857em;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    border: 1px solid transparent;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    
}

.main-btn:hover{
    box-sizing: border-box;
    border: 1px solid var(--backgroundColorJson);
    color: #15a4ae;
    // outline: 1px #15a4ae solid;
    background-color: rgb(242, 242, 242);

}

.main-btn:active{
    background-color: lightgray;
}

.reverse-color{
    color: var(--white);
    background-color: var(--backgroundColorJson);
    
}

.reverse-color:active{
    background-color: lightgray;
    color: var(--backgroundColorJson);
}

.reverse-color:hover{
    border: 1px solid transparent;
    // color: #15a4ae;
    background-color: var(--white);
    // outline: 1px var(--backgroundColorJson) solid;
    color: var(--backgroundColorJson);

    opacity: 0.9;

}



.pointer{
    cursor: pointer;
}

* {
    font-family: General-Font;
}

body {
    color: var(--fontColorDark);
    background-color: var(--white);
}

.visible-class{
    visibility: visible;
}
.hide-class{
    visibility: hidden;
}

.block-class{
    display: block;

}

.none-class{
    display: none;
}

.min-height{
    min-height: 60vh;
}



.move-left{
    left: 0% !important;

}

.vh-80 {
    height: 80vh
}

.sign-btn {
    margin-top: 5rem;
    background-color: var(--backgroundColorJson);
}

.sign-btn-oposite {
    background-color: var(--white) !important;
    // color: var(--backgroundColorJson) !important;
    color: var(--fontColorDark) !important; 


}

.titleText {
    border-bottom: 1px solid rgb(221, 221, 221);
    text-align: center;
    margin: 4rem auto;
    font-size: 1rem;
}

.button-border{
    border-radius: 1rem;
}

.add-padding{
    padding: 1rem !important;
}

.attached-top-bottom{
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.margin-top-auto{
    margin-top: auto;
}

.btn-status{
    h3{
        padding: 0.5rem;
    }
}

tbody{
    td{
        direction: rtl;
    }
}



