:root {
    --backgroundColorJson: #fff;
    --fontColorJson: #ECECEC;
    --fontColorDark: black;
    --coverImage: url("../../assets/images/background.jpeg");
    --details-img: url("../../assets/images/background.jpeg");
    --contact-img: url("../../assets/images/background.jpeg");
    --white: #F7F7F7;
}


