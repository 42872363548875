.contact-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.contact-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    padding: 1rem 10%;
    
    .contact-form {
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        .css-1nrlq1o-MuiFormControl-root{
            span{
                padding: 0;
            }
        }

        button{
            cursor: pointer;
            background-color: black;
            border-radius: 5px;
            margin-top: 1rem;
        }

        input{
            text-align: right;
        }

        form {

            display: flex;
            flex-direction: column;
            width: 90%;
        }

        .form-group {
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }
}

.contact-img {
    // max-height: 85rem; 
    text-align: center;
    // height: 36rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    img {
        height: 34rem;
    /* max-width: 28rem; */
    border-radius: 1rem;
    object-fit: cover;
    /* aspect-ratio: 1/1; */
    width: 100%;
    }
}

.contact-boxes-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    align-items: center;
    width: 100%;
    padding: 1rem 20%;
    border-bottom: 1px solid rgb(221, 221, 221);
    gap: 1rem;
    // background-color: #E5E5E5;

    .contact-box{
        padding: 1rem;
        border-radius: 1rem;
        background-color: var(--backgroundColorJson);
        color: var(--white);
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.msg-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--backgroundColorJson);
    color: var(--white);
    border-radius: 1rem;
    transform: translate(-50%,-50%);
    padding: 1rem;
    z-index: 102;
    display: flex;
    flex-direction: column;

    span{
        align-self: flex-end;
    }

}

.main-contact{
    .dark-home{
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
    }
}