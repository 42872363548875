.enrollment-table {
    text-align: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    span{
        color: red;
    }

    

    h1:first-child{
        // margin-top: 10rem;
    }
    .container{
        h1{
            margin-bottom: 1rem;
        }
        // margin-top: 8rem;
    }

    
    
    table {
        margin: 0 auto;
        border-collapse: collapse;
        td,tr,th{
            padding: 0.4rem;
            border: 1px solid var(--backgroundColorJson);
        }
        th{
            background-color: var(--backgroundColorJson);
            color: var(--fontColorJson);
        }
        
       
    }

    p {
        padding: 5rem;
        direction: rtl;
        text-align: right;
    }
}