.DetailsContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: right;
    align-items: right;
    direction: rtl;
    padding: 2rem;


    .schedule{
        h1{
            text-align: center;
        }
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }


    .icon {
        color: var(--backgroundColorJson);
        font-size: 2rem;
        cursor: pointer;

    }

    div {
        text-align: right;
    }


    table {
        border-collapse: collapse;
        direction: ltr;

        th{
            text-align: center;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        td,
        tr,
        th {
            direction: rtl;
            border: 1px solid lightgray;
        }

        th {
            background-color: lightgrey;
            color: var(--backgroundColorJson);
        }
    }

}

.prices{
    table{
        margin: 0 auto;
    }
}

.meeting-point{
    text-align: center !important;


    a{
        
            margin: 1rem auto;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            max-width: 10rem;
            background: white;
            color: var(--backgroundColorJson);
            border-radius: 5px;
            padding: 0.2rem;
        

    }

    svg{
        color: unset !important;
    }

}

.details-icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    padding: 1rem;
    gap: 1rem;
    div{
        text-align: center;
    }
}

.details-modal2 {
    background-color: var(--backgroundColorJson);
    // padding: 1rem;
    color: var(--fontColorJson);
    border-radius: 1rem
}

.resaluts-container{
    div:first-child{
        text-align: center;
        div{
            text-align: right;
        }
    }
}

.terms-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    // .terms-btn{
    //     display: flex;
    //     flex-direction: column;
    //     gap: 1rem;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    //     // width: 100%;
    // }
    align-items: center;

    
}