.sponsor-card {
    cursor: pointer;
    display: flex;
    aspect-ratio: 1/1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 6px 16px rgb(0 0 0 / 12%);
    border: 1px solid #ddd;
    background: white;
    margin: 1rem;
    border-radius: 12px;
    z-index: 8;
    text-align: center;
    gap: 1rem;
    width: 11rem;
    margin: 1rem auto;

    .upper {
        padding-top: 0.5rem;
    }


    .bellow {
        min-height: 3rem;
        display: flex;
        // align-items: center;
    }

    img {
        width: 6rem;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
}