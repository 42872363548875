.galleryWrap {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));



    padding: 2rem;
    z-index: 8;
}

.galleryWrap .single {
    cursor: pointer;
    z-index: 8;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.galleryWrap .single img {
    width: 100%;
    border-radius: 1rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 200px;


}

.galleryWrap .single img:hover {
    transform: scale(1.02);
}

.sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    
    .btnClose,
    .btnPrev,
    .btnNext {
        font-size: 2rem;
        font-weight: bold;
        position: fixed;
        cursor: pointer;
        opacity: 0.6;
        color: black;
        z-index: 9999;
        border-radius: 50%;
        background-color: white;
       
    }

    .btnClose {
        top: 2rem;
        right: 2rem;
    }

    .btnPrev {
        top: 50%;
        transform: translateY(-50%);
        left: 2rem;
    }

    .btnNext {
        top: 50%;
        transform: translateY(-50%);
        right: 2rem;
    }

}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
    opacity: 1;
    
}











.fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
        max-height: 100%;
        pointer-events: none;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }
}