/* MOBILE ONLY  */

@media (max-width: 640px) {

    .egud-box {


        img {
            max-width: 200px !important;
        }

        .left-side,
        .right-side {
            min-width: 200px !important;
            width: 100% !important;
        }

        h2 {
            font-size: 1.2rem;
        }

        margin-left: 2rem;
        margin-right: 2rem;
    }

    .main-info {
        display: flex !important;
        flex-direction: column;

    }

    .sponsor-card {
        // max-width: 9rem;
    }

    .top-0rem {
        bottom: 2rem !important;
    }

    .no-reg-counter {
        position: fixed !important;
        bottom: 4rem !important;
        width: 100%;
        left: 0;
        border-bottom-left-radius: 0 !important;
        top: unset !important;
        z-index: 10;
    }

    .slide-in-right {
        -webkit-animation: unset !important;
        animation: unset !important;
    }




    .countdown-container {
        width: 1rem !important;

        // display: none !important;
    }

    .titleText {
        margin-top: 1rem !important;
    }

    .main-details-container {
        display: none;
    }

    .main-details-container {
        grid-row: 1;
    }

    .sponsers-container {


        // grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr))
    }

    body {
        padding-bottom: 10rem;
    }

    .contact-img {
        display: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .enrollment-table {
        padding: 1rem !important;

        p {
            padding: 1rem !important;
        }

    }

    .titleText {
        visibility: hidden;
        text-align: right !important;
        padding-right: 2rem;
    }

    .map-container {
        padding: 3rem 2%;
    }

    .sponsers-container {
        margin-bottom: 4rem;

    }



    .exist-page {
        display: block;
        width: 100%;
        text-align: right;
        margin-right: 1rem;
        // display: none;
    }

    .footer-container {
        display: none !important;
    }


    .main-header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }

    .header-home {
        h1 {
            font-size: 2.5rem !important;
        }

        h2 {
            font-size: 1.5rem !important;
        }
    }






    .navbar {
        // visibility: hidden;

        ul {
            height: calc(100% - 4rem);
            left: 100%;
            transition: left 0.5s;
            padding: 0;
            padding-top: 5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            display: flex;
            z-index: -1;
            flex-direction: column;
            position: fixed;
            right: 0;
            bottom: 0;
            width: 100%;
            gap: 0.5rem;
            top: 64px;
            background-color: var(--backgroundColorJson);
            flex-direction: column-reverse;
            justify-content: start;

            li {
                font-size: 1rem;
                text-align: center;
                padding: 1rem;
                border-bottom: 1px solid var(--fontColorJson);


            }
        }
    }



    .log-in-modal {
        position: fixed !important;
        bottom: 0;
        top: unset !important;
        width: 100%;
        right: 0;
        border-bottom-left-radius: unset !important;
        flex-direction: column-reverse !important;
        padding-bottom: 1.5rem !important;



        // visibility: hidden;
    }

    .show-counter {
        position: fixed !important;
        bottom: 7rem;
        left: 0;
        border-bottom-left-radius: 0 !important;
        top: unset !important;
        padding-top: unset;
    }



    .main-nav {
        width: 85%;
        margin: auto;
        display: unset;
        margin-top: 1rem;
        height: unset;
    }





}



// tablet
@media (min-width: 640px) {

    .egud-box {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .route-header {

        font-size: 2rem;
    }

    table {
        font-size: 1.5rem;
    }

    .sponsor-card {
        // max-width: 11rem;
    }

    .close,
    .close1 {
        display: none !important;
    }

    .show-counter {
        top: 11rem;
        padding-top: 1rem;
    }

    .top-0rem {
        top: 6rem !important;
    }

    .exist-page {
        display: none;
    }

    .sponsers-container {


        // grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr))
    }

    .map-container {
        padding: 3rem 20%;
    }



    .navbar {
        ul {
            padding: 0;

            li {
                margin-left: 1rem;
            }
        }
    }

    // .close{
    //     display: none;
    // }

    .hamburger {
        display: none !important;
    }

    .main-footer {
        * {

            font-size: 0.9em !important;
        }
    }


}

// /* LARGE */

@media (min-width: 920px) {


    .egud-box {


        margin: 2rem auto;
        max-width: 70%;

    }

    .main-footer {
        * {

            font-size: 1em !important;
        }
    }

    .details-icons {
        grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)) !important;
    }


}