.countdown-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    // bottom: 0;
    position: absolute;
    right: 0rem;
    padding-top: 4rem;
    color: var(--fontColorJson);
    width: 14rem;
    
    .log-in-modal {
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--backgroundColorJson);
        font-weight: bold;
        text-align: center;
        position: sticky;
        top: 0;
        border-bottom-left-radius: 1rem;
        gap: 0.5rem;
        padding: 1rem;
        
        a {
            color: var(--fontColorDark);
            // margin-top: 1rem;
        }

    }

    .countdown {
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        z-index: 1;

        p {
            text-align: center;
        }
    }
}

.show-counter {
    display: flex;
    flex-direction: row;
    // padding-top: 1rem;
    margin-top: -1rem;
    // top: 10rem;
    width: 100%;
    border-bottom-left-radius: 0.75rem;
    z-index: 20;
    justify-content: center;
    background-color: var(--backgroundColorJson);
    border-bottom-left-radius: 1rem;
    position: absolute;
    transition: all 2s;
    // transition: bottom 2s;
    



}

.counter-bc {
    margin-left: 2rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 200px 0 0 200px;
    border-color: transparent transparent transparent #007bff;
    position: fixed;
    bottom: 0;
}

.no-reg-counter{
    background-color: var(--backgroundColorJson);
    padding: 1rem;
    padding-top: 2rem;
    border-bottom-left-radius: 1rem;
    width: 100%;
    margin-top: -3rem;
    transition: all 2s;
    position: absolute;
    top: 10rem;
    // bottom: unset !important

}