.main-details-container {
    .first-img {
        width: 100%;
        padding: 2rem 2rem 2rem 1rem;
        height: 20rem;
        margin-bottom: 5rem;


        img {
            box-shadow: 1rem 1.25rem var(--backgroundColorJson);
            height: 20rem;
            border-radius: 1rem;
            aspect-ratio: 16/9;
            object-fit: cover;
            width: 100%
        }
    }

    .seconed-img {
        width: 100%;
        background-color: var(--backgroundColorJson);
        height: 20rem;
        border-radius: 1rem;
        margin-top: -19rem;
        margin-left: 1rem
    }
}

.custom-notes {
    padding: 1rem 1rem 1rem 0rem;
    h3 {
        text-align: right;
    }
}

.content-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}


.json-par{
    border: 1px var(--backgroundColorJson) solid;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 1rem 1.25rem var(--backgroundColorJson);
    margin: 0 1rem;
    width: 100%;
    margin-bottom: 1rem;
    z-index: 8;
    *{
        
        background-color: var(--white) !important;
    }
    a{
        color: blue;
        text-decoration: underline;
        cursor: pointer;
        z-index: 22222222222;
    }
}
.categories {
    text-align: right !important;
    h1{
        text-align: center;
    }
    div {
        h3:first-child {
            text-decoration: underline;
            font-weight: bold;
        }
    }
}

.custom-notes{
    display: flex;
}

