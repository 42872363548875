

.main-header {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    align-items: center;
    background-color: var(--backgroundColorJson);
    // color: var(--white);
    padding-right: 1rem;
    color: var(--fontColorJson);
    z-index: 102;

    

    a {
        cursor: pointer;
    }

}

.active-class{
    font-family: Bold-Font;
    // text-decoration: underline;
}

.not-active-class{
    text-decoration: none;
}

.background-div{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(8, 8, 8, 0.8)  ;
    visibility: hidden;  // background-color: antiquewhite;
    
    
}

.logo {
    z-index: 103;
    li {
        height: 4rem;

        img {
            height: 7rem;
            border: 1px solid var(--white);
            border-radius: 1rem;
            margin: 1rem;
            box-shadow: 0 6px 16px rgb(0 0 0 / 12%);
            border: 1px solid #ddd;
            aspect-ratio: 1/1;
            object-fit: cover;
        }
    }
}

.close{
    display: none;
    // position: absolute;
    // top: 90%;
    // left: 50%;
    // transform: translateX(-50%);
    // font-size: 3rem;
}
.close1{
    // display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3rem;
    bottom: 10%;
}

.navbar {
    padding: 1rem 0 1rem 0;

    ul {
        display: flex;

        li {
            // margin-left: 1rem;
        }
    }
}